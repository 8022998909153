import React from "react";
import { Link } from "gatsby";
import Image from "gatsby-image";
import PostMeta from "./PostMeta";
// import PostTags from "./PostTags";
import styles from "../styles/PostCard.module.scss";

class PostCard extends React.Component {
  render() {
    const post = this.props.post;
    const {
      fields: { slug: path },
      frontmatter: { poster, title, description, cover_image: coverImage },
    } = post;

    return (
      <div
        className={[
          styles.postCard,
          "content-box",
          poster && "postCard--hasPoster",
        ].join(" ")}
      >
        {coverImage && (
          <div className={styles.postCard__header}>
            <Image
              alt="Cover image"
              className={styles.postCard__image}
              fixed={coverImage.childImageSharp.fixed}
              style={{ width: "100%" }}
            />
          </div>
        )}
        <div className={styles.postCard__content}>
          <h2 className={styles.postCard__title}>{title}</h2>
          <p className={styles.postCard__description}>
            {description || post.excerpt}
          </p>

          <PostMeta className={styles.postCard__meta} post={post} />
          {/* {tags && <PostTags className={styles.postCard__tags} tags={tags} />} */}

          <Link className={styles.postCard__link} to={path}>
            Link
          </Link>
        </div>
      </div>
    );
  }
}

export default PostCard;
